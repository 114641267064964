import actions from '../actions/types';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';
import VizState from '../../types/VizState';

const initialState: VizState = {
    svg: '',
};

export { initialState };

const actionsSwitch = (state: VizState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.viz.SET_SVG: {
            return updateObject(state, { svg: action.payload.svg });
        }
        default:
            return state;
    }
};

export default actionsSwitch;
