import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import * as serviceWorker from './serviceWorker';
import ru_Ru from 'antd/lib/locale-provider/ru_RU';
import { ConfigProvider } from 'antd';
// import sampleOutput from './sampleData/sampleOutput';
import { Provider } from 'react-redux';
import reducers from './store/reducers';
import { createStore, Store } from 'redux';

import storeService from './services/storeService';
import { composeWithDevTools } from 'redux-devtools-extension';
import vizIntegrationService from './services/vizIntegrationService';
import integrationService from './services/integrationService';

import pjson from '../package.json';
import actions from './store/actions/types';
import graylogService from './services/graylogServices';
import dispatcherService from './services/dispatcherService';

const App = React.lazy(() => import('./App'));

const store: Store = createStore(reducers, {}, composeWithDevTools());
storeService.setStore(store);
vizIntegrationService(storeService);
integrationService(storeService);

const root = document.getElementById('webbot');
(async () => {
    await dispatcherService.setParamsByElement(root);

    let loadConfigRequest = await integrationService(storeService).loadConfig();

    if (loadConfigRequest.status === 200) {
        const config = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_SUCCESS,
            payload: config,
        });
    } else {
        let body = await loadConfigRequest.json();

        storeService.getStore().dispatch({
            type: actions.general.FETCH_CONFIG_ERROR,
            payload: body,
        });

        dispatcherService.setMode('warning');
        dispatcherService.setWarningType('get_config');

        await graylogService.sendMessage({
            short_message: `[${loadConfigRequest.status}] Request error: ${loadConfigRequest.url}`,
            full_message: `[${loadConfigRequest.status}] Request error: ${loadConfigRequest.url}`,
            _body: integrationService(storeService).buildPleBody(
                storeService.getStore().getState().general.integrationParams
            ),
            _body_result: body,
        });
    }

    await ReactDOM.render(
        <Provider store={store}>
            <ConfigProvider locale={ru_Ru}>
                <Suspense fallback={<></>}>
                    <App />
                </Suspense>
            </ConfigProvider>
        </Provider>,
        root
    );
})();

console.log(
    `%c Life Journey version: ${pjson.version} `,
    'background-color: #ffdd2d; color: #333;'
);

// console.log(JSON.stringify(sampleOutput, null, 2));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
