import storeService, { StoreService } from './storeService';
import graylogService from './graylogServices';
import dispatcherService from './dispatcherService';
import features from '../release-features.json';
import { message } from 'antd';

const lrs = 'https://lrs.u2035test.ru';
const environment = {
    LRSResultFormat: `${lrs}/xapi/v1/results/jifejourney`,
    proxyHost: 'https://bypass.2035.university/api/activity',
    t: 'c8d46d341bea4fd5bff866a65ff8aea9',
    production: true,
};

interface IframeParams {
    token: string;
    unti_id: string;
    activity_uuid: string;
}

export class IntegrationService {
    storeService: StoreService;
    private interval: NodeJS.Timeout | undefined;

    constructor(storeService: StoreService) {
        this.storeService = storeService;
        storeService.setQuestionnaireChangeHandler(this.saveUserStateHandler);
    }

    public loadConfig() {
        const currentState = this.storeService.getStore().getState().general;
        const { activity_uuid } = currentState.integrationParams;

        return fetch(
            'https://bypass.2035.university/api/activity/' + activity_uuid + '/get_config'
        );
    }

    public sendStart(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/start`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    public sendEnd(params: IframeParams): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/end`, {
            body: this.buildPleBody(params),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    public sendResult(params: IframeParams, result: any): Promise<Response> {
        return fetch(`${environment.proxyHost}/${this.getActivityUUIDForLight()}/lrsdata`, {
            body: this.buildLrsBody(params, result),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    public getUserState(params: IframeParams) {
        return fetch(`${environment.proxyHost}/${this.getActivityUUIDForLight()}/load`, {
            method: 'POST',
            body: JSON.stringify({
                unti_id: params.unti_id,
                token: params.token,
                t: environment.t,
            }),
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    private getCountdown(count: number): number {
        return count >= 3 ? 15 * count : count === 1 ? 20 : count === 2 ? 30 : 15;
    }

    private resetCountdown() {
        const generalState = this.storeService.getStore().getState().general;
        dispatcherService.setRetryDeadline(this.getCountdown(generalState.retryCount));
        dispatcherService.setRetryCount(generalState.retryCount + 1);
    }

    public saveUserStateHandler = async () => {
        const generalState = this.storeService.getStore().getState().general;
        const { firstLoad, isSavingQuestionnaires } = generalState;

        if (!firstLoad) return;

        const isFinished = await this.finish();
        if (!isFinished) {
            await this.retrySendResult();
        } else {
            if (!isSavingQuestionnaires) message.success('Ваши данные успешно сохранены');
        }
    };

    async retrySendResult() {
        dispatcherService.setRetrying(true);

        const self = this;

        if (!this.interval)
            this.interval = setInterval(async function() {
                const retryDeadline = storeService.getStore().getState().general.retryDeadline - 1;
                dispatcherService.setRetryDeadline(retryDeadline);

                if (retryDeadline <= 0) {
                    clearInterval(self.interval!);
                    self.interval = undefined;

                    const isFinished = await self.finish();
                    if (!isFinished) {
                        self.resetCountdown();
                        await self.retrySendResult();
                    } else {
                        message.success('Ваши данные успешно сохранены');
                        dispatcherService.setRetrying(false);
                    }
                }
            }, 1000);
    }

    private async finish() {
        const generalState = this.storeService.getStore().getState().general;
        const { integrationParams, questionnaires } = generalState;

        await dispatcherService.setIsSavingQuestionnaires(true);

        let allQuestionnaires = [
            ...questionnaires.achievements,
            ...questionnaires.career,
            ...questionnaires.education,
            ...questionnaires.hobby,
            ...questionnaires.insights,
            ...questionnaires.remarkableEvents,
        ];

        let progressPercent = allQuestionnaires.length * 5;
        progressPercent = progressPercent <= 95 ? progressPercent : 95;

        await this.reportProgress(integrationParams, progressPercent);

        let saveUserStateRequest = await this.saveUserState(integrationParams, questionnaires);
        let sendResultRequest = await this.sendResult(integrationParams, questionnaires);

        if (saveUserStateRequest.status !== 200)
            await graylogService.sendMessage({
                short_message: `[${saveUserStateRequest.status}]  Request error: ${saveUserStateRequest.url}`,
                full_message: `[${saveUserStateRequest.status}]  Request error: ${saveUserStateRequest.url}`,
                _body: this.buildPleBody(integrationParams),
            });
        if (sendResultRequest.status !== 200)
            await graylogService.sendMessage({
                short_message: `[${sendResultRequest.status}]  Request error: ${sendResultRequest.url}`,
                full_message: `[${sendResultRequest.status}]  Request error: ${sendResultRequest.url}`,
                _body: this.buildLrsBody(integrationParams, questionnaires),
            });

        await dispatcherService.setIsSavingQuestionnaires(false);

        if (saveUserStateRequest.status !== 200 || sendResultRequest.status !== 200) {
            // dispatcherService.setMode('warning');
            // dispatcherService.setWarningType('result');

            return false;
        } else {
            if (allQuestionnaires.length >= features.minimalEntries) {
                let sendEndRequest = await this.sendEnd(integrationParams);

                if (sendEndRequest.status !== 200)
                    await graylogService.sendMessage({
                        short_message: `[${sendEndRequest.status}]  Request error: ${sendEndRequest.url}`,
                        full_message: `[${sendEndRequest.status}]  Request error: ${sendEndRequest.url}`,
                        _body: this.buildPleBody(integrationParams),
                    });

                dispatcherService.setShowMinQuestionnairesModal(true);
            }
        }

        return true;
    }

    public saveUserState(params: IframeParams, profile: any) {
        return fetch(`${environment.proxyHost}/${this.getActivityUUIDForLight()}/save`, {
            body: JSON.stringify({
                unti_id: params.unti_id,
                token: params.token,
                t: environment.t,
                state: profile,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
        });
    }

    public reportProgress(params: IframeParams, progress: number): Promise<Response> {
        return fetch(`${environment.proxyHost}/${params.activity_uuid}/progress`, {
            body: JSON.stringify({
                ...JSON.parse(this.buildPleBody(params)),
                progress: progress,
            }),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    public buildLrsBody(params: IframeParams, result: any) {
        const extensions = {
            [environment.LRSResultFormat]: result,
        };

        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
            extensions,
        });
    }

    public buildPleBody(params: IframeParams) {
        return JSON.stringify({
            unti_id: params.unti_id,
            token: params.token,
            t: environment.t,
        });
    }

    public getActivityUUIDForLight(): string {
        const currentState = this.storeService.getStore().getState().general;
        return currentState.source_activity_uuid || currentState.integrationParams.activity_uuid;
    }
}

let _is: IntegrationService;

const integrationService = (storeService: StoreService) => {
    if (!_is) {
        _is = new IntegrationService(storeService);
    }
    return _is;
};

export default integrationService;
