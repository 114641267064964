import actions from '../store/actions/types';
import storeService from './storeService';
import EducationOutputItem from '../types/Output/EducationOutputItem';
import CareerOutputItem from '../types/Output/CareerOutputItem';
import HobbyOutputItem from '../types/Output/HobbyOutputItem';
import RemarkableEventOutputItem from '../types/Output/RemarkableEventOutputItem';
import AchievementOutputItem from '../types/Output/AchievementOutputItem';
import InsightOutputItem from '../types/Output/InsightOutputItem';
import IntegrationParams from '../types/IntegrationParams';
import Output from '../types/Output/Output';
import SummaryQuestionnaires from '../types/SummaryQuestionnaires';
import Mode from '../types/Mode';
import WarningType from '../types/WarningType';

class DispatcherService {
    setParamsByElement = async (element: HTMLElement | null): Promise<void> => {
        if (element) {
            const token = element.getAttribute('data-user-token'),
                unti_id = element.getAttribute('data-unti-id'),
                activity_uuid = element.getAttribute('data-activity-uuid');

            await storeService.getStore().dispatch({
                type: actions.general.SET_INTEGRATION_PARAMS,
                payload: {
                    token: token,
                    unti_id: unti_id,
                    activity_uuid: activity_uuid,
                } as IntegrationParams,
            });
        }
    };

    saveQuestionnaireEducation = (questionnaire: EducationOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_EDUCATION,
            payload: { questionnaire },
        });
    };

    saveQuestionnaireCareer = (questionnaire: CareerOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_CAREER,
            payload: { questionnaire },
        });
    };

    saveQuestionnaireHobby = (questionnaire: HobbyOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_HOBBY,
            payload: { questionnaire },
        });
    };

    saveQuestionnaireRemarkableEvent = (questionnaire: RemarkableEventOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_REMARKABLE_EVENT,
            payload: { questionnaire },
        });
    };

    saveQuestionnaireAchievement = (questionnaire: AchievementOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_ACHIEVEMENT,
            payload: { questionnaire },
        });
    };

    saveQuestionnaireInsight = (questionnaire: InsightOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_QUESTIONNAIRE_INSIGHT,
            payload: { questionnaire },
        });
    };

    changeAchievementTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_ACHIEVEMENT_TITLE,
            payload: { index, newTitle },
        });
    };

    changeCareerTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CAREER_TITLE,
            payload: { index, newTitle },
        });
    };

    changeEducationTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_EDUCATION_TITLE,
            payload: { index, newTitle },
        });
    };

    changeHobbyTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_HOBBY_TITLE,
            payload: { index, newTitle },
        });
    };

    changeRemarkableEventTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_REMARKABLE_EVENT_TITLE,
            payload: { index, newTitle },
        });
    };

    changeInsightTitle = (index: number, newTitle: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_INSIGHT_TITLE,
            payload: { index, newTitle },
        });
    };

    removeQuestionnaireEducation = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_EDUCATION,
            payload: { index },
        });
    };

    removeQuestionnaireCareer = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_CAREER,
            payload: { index },
        });
    };

    removeQuestionnaireHobby = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_HOBBY,
            payload: { index },
        });
    };

    removeQuestionnaireRemarkableEvent = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_REMARKABLE_EVENT,
            payload: { index },
        });
    };

    removeQuestionnaireAchievement = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_ACHIEVEMENT,
            payload: { index },
        });
    };

    removeQuestionnaireInsight = (index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.REMOVE_QUESTIONNAIRE_INSIGHT,
            payload: { index },
        });
    };

    changeQuestionnaireEducation = (questionnaire: EducationOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_EDUCATION,
            payload: { questionnaire },
        });
    };

    changeQuestionnaireCareer = (questionnaire: CareerOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_CAREER,
            payload: { questionnaire },
        });
    };

    changeQuestionnaireHobby = (questionnaire: HobbyOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_HOBBY,
            payload: { questionnaire },
        });
    };

    changeQuestionnaireRemarkableEvent = (questionnaire: RemarkableEventOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_REMARKABLE_EVENT,
            payload: { questionnaire },
        });
    };

    changeQuestionnaireAchievement = (questionnaire: AchievementOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_ACHIEVEMENT,
            payload: { questionnaire },
        });
    };

    changeQuestionnaireInsight = (questionnaire: InsightOutputItem) => {
        storeService.getStore().dispatch({
            type: actions.general.CHANGE_QUESTIONNAIRE_INSIGHT,
            payload: { questionnaire },
        });
    };

    changeCondition = (condition: string) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_CONDITION,
            payload: { condition },
        });
    };

    changeEditingQuestionnaireCondition = (type: string, index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_EDITING_QUESTIONNAIRE_CONDITION,
            payload: { type, index },
        });
    };

    setSvg = (svg: string) => {
        storeService.getStore().dispatch({
            type: actions.viz.SET_SVG,
            payload: { svg },
        });
    };

    setIntegrationParams = (params: IntegrationParams) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_INTEGRATION_PARAMS,
            payload: { params },
        });
    };

    setLoadedQuestionnaires = (questionnaires: Output) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_LOADED_QUESTIONNAIRES,
            payload: { questionnaires },
        });
    };

    getQuestionnairesRequest = () => {
        storeService.getStore().dispatch({
            type: actions.general.GET_QUESTIONNAIRES_REQUEST,
            payload: {},
        });
    };

    getQuestionnairesSuccess = () => {
        storeService.getStore().dispatch({
            type: actions.general.GET_QUESTIONNAIRES_SUCCESS,
            payload: {},
        });
    };

    getQuestionnairesFailure = () => {
        storeService.getStore().dispatch({
            type: actions.general.GET_QUESTIONNAIRES_FAILURE,
            payload: {},
        });
    };

    setSummaryQuestionnaires = (summaryQuestionnaires: SummaryQuestionnaires) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_SUMMARY_QUESTIONNAIRES,
            payload: { summaryQuestionnaires },
        });
    };

    changeEditingSummaryQuestionnaireCondition = (type: string, index: number) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_EDITING_SUMMARY_QUESTIONNAIRE_CONDITION,
            payload: { type, index },
        });
    };

    setIsSavingQuestionnaires = (payload: boolean) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_IS_SAVING_QUESTIONNAIRES,
            payload: payload,
        });
    };

    setNextEditingSummaryQuestionnaireCondition = () => {
        const currentState = storeService.getStore().getState().general,
            summaryQuestionnaires = currentState.summaryQuestionnaires,
            editingSummaryQuestionnaireIndex = currentState.editingSummaryQuestionnaireIndex,
            editingSummaryQuestionnaireType = currentState.editingSummaryQuestionnaireType;

        if (
            summaryQuestionnaires[editingSummaryQuestionnaireType][
                editingSummaryQuestionnaireIndex + 1
            ]
        ) {
            this.changeEditingSummaryQuestionnaireCondition(
                editingSummaryQuestionnaireType,
                editingSummaryQuestionnaireIndex + 1
            );
            this.changeCondition('fillingQuestionnaire');
        } else {
            let questionnairesTypes = ['career', 'education', 'hobby'];
            questionnairesTypes = questionnairesTypes.slice(
                questionnairesTypes.findIndex(item => item === editingSummaryQuestionnaireType) + 1
            );

            questionnairesTypes.every(type => {
                if (summaryQuestionnaires[type].length > 0) {
                    this.changeEditingSummaryQuestionnaireCondition(type, 0);
                    this.changeCondition('fillingQuestionnaire');
                    return false;
                } else if (type === questionnairesTypes[questionnairesTypes.length - 1]) {
                    this.changeCondition('viewData');
                    this.setSummaryQuestionnaires({
                        career: [],
                        education: [],
                        hobby: [],
                    });
                    return false;
                }

                return true;
            });
        }
    };

    setMode = (mode: Mode) => {
        storeService.getStore().dispatch({
            type: actions.general.SET_MODE,
            payload: mode,
        });
    };

    setWarningType = (type: WarningType): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_WARNING_TYPE,
            payload: type,
        });
    };

    setShowMinQuestionnairesModal = (show: boolean): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_SHOW_MIN_QUESTIONNAIRES_MODAL,
            payload: show,
        });
    };

    setFirstLoad = (loaded: boolean): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_FIRST_LOAD,
            payload: loaded,
        });
    };

    setRetryCount = (retryCount: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_RETRY_COUNT,
            payload: retryCount,
        });
    };

    setRetryDeadline = (retryDeadline: number): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_RETRY_DEADLINE,
            payload: retryDeadline,
        });
    };

    setRetrying = (retrying: boolean): void => {
        storeService.getStore().dispatch({
            type: actions.general.SET_RETRYING,
            payload: retrying,
        });
    };
}

const dispatcherService = new DispatcherService();
export default dispatcherService;
