const actions = {
    general: {
        SET_QUESTIONNAIRE_EDUCATION: 'SET_QUESTIONNAIRE_EDUCATION',
        SET_QUESTIONNAIRE_CAREER: 'SET_QUESTIONNAIRE_CAREER',
        SET_QUESTIONNAIRE_HOBBY: 'SET_QUESTIONNAIRE_HOBBY',
        SET_QUESTIONNAIRE_REMARKABLE_EVENT: 'SET_QUESTIONNAIRE_REMARKABLE_EVENT',
        SET_QUESTIONNAIRE_ACHIEVEMENT: 'SET_QUESTIONNAIRE_ACHIEVEMENT',
        SET_QUESTIONNAIRE_INSIGHT: 'SET_QUESTIONNAIRE_INSIGHT',
        REMOVE_QUESTIONNAIRE_EDUCATION: 'REMOVE_QUESTIONNAIRE_EDUCATION',
        REMOVE_QUESTIONNAIRE_CAREER: 'REMOVE_QUESTIONNAIRE_CAREER',
        REMOVE_QUESTIONNAIRE_HOBBY: 'REMOVE_QUESTIONNAIRE_HOBBY',
        REMOVE_QUESTIONNAIRE_REMARKABLE_EVENT: 'REMOVE_QUESTIONNAIRE_REMARKABLE_EVENT',
        REMOVE_QUESTIONNAIRE_ACHIEVEMENT: 'REMOVE_QUESTIONNAIRE_ACHIEVEMENT',
        REMOVE_QUESTIONNAIRE_INSIGHT: 'REMOVE_QUESTIONNAIRE_INSIGHT',
        CHANGE_QUESTIONNAIRE_EDUCATION: 'CHANGE_QUESTIONNAIRE_EDUCATION',
        CHANGE_QUESTIONNAIRE_CAREER: 'CHANGE_QUESTIONNAIRE_CAREER',
        CHANGE_QUESTIONNAIRE_HOBBY: 'CHANGE_QUESTIONNAIRE_HOBBY',
        CHANGE_QUESTIONNAIRE_REMARKABLE_EVENT: 'CHANGE_QUESTIONNAIRE_REMARKABLE_EVENT',
        CHANGE_QUESTIONNAIRE_ACHIEVEMENT: 'CHANGE_QUESTIONNAIRE_ACHIEVEMENT',
        CHANGE_QUESTIONNAIRE_INSIGHT: 'CHANGE_QUESTIONNAIRE_INSIGHT',
        SET_CONDITION: 'SET_CONDITION',
        SET_EDITING_QUESTIONNAIRE_CONDITION: 'SET_EDITING_QUESTIONNAIRE_CONDITION',
        SET_ACHIEVEMENT_TITLE: 'SET_ACHIEVEMENT_TITLE',
        SET_CAREER_TITLE: 'SET_CAREER_TITLE',
        SET_EDUCATION_TITLE: 'SET_EDUCATION_TITLE',
        SET_HOBBY_TITLE: 'SET_HOBBY_TITLE',
        SET_REMARKABLE_EVENT_TITLE: 'SET_REMARKABLE_EVENT_TITLE',
        SET_INSIGHT_TITLE: 'SET_INSIGHT_TITLE',
        SET_INTEGRATION_PARAMS: 'SET_INTEGRATION_PARAMS',
        SET_LOADED_QUESTIONNAIRES: 'SET_LOADED_QUESTIONNAIRES',
        GET_QUESTIONNAIRES_REQUEST: 'GET_QUESTIONNAIRES_REQUEST',
        GET_QUESTIONNAIRES_SUCCESS: 'GET_QUESTIONNAIRES_SUCCESS',
        GET_QUESTIONNAIRES_FAILURE: 'GET_QUESTIONNAIRES_FAILURE',
        SET_SUMMARY_QUESTIONNAIRES: 'SET_SUMMARY_QUESTIONNAIRES',
        SET_EDITING_SUMMARY_QUESTIONNAIRE_CONDITION: 'SET_EDITING_SUMMARY_QUESTIONNAIRE_CONDITION',
        SET_IS_SAVING_QUESTIONNAIRES: 'SET_IS_SAVING_QUESTIONNAIRES',
        SET_MODE: 'SET_MODE',
        SET_WARNING_TYPE: 'SET_WARNING_TYPE',
        FETCH_CONFIG: 'FETCH_CONFIG',
        FETCH_CONFIG_SUCCESS: 'FETCH_CONFIG_SUCCESS',
        FETCH_CONFIG_ERROR: 'FETCH_CONFIG_ERROR',
        SET_SHOW_MIN_QUESTIONNAIRES_MODAL: 'SET_SHOW_MIN_QUESTIONNAIRES_MODAL',
        SET_FIRST_LOAD: 'SET_FIRST_LOAD',
        SET_RETRY_COUNT: 'SET_RETRY_COUNT',
        SET_RETRY_DEADLINE: 'SET_RETRY_DEADLINE',
        SET_RETRYING: 'SET_RETRYING',
    },
    viz: { SET_SVG: 'SET_SVG' },
};

export default actions;
