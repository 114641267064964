import { StoreService } from './storeService';
import dispatcherService from './dispatcherService';
import Output from '../types/Output/Output';
import graylogService from './graylogServices';

class VizIntegrationService {
    storeService: StoreService;

    constructor(storeService: StoreService) {
        this.storeService = storeService;
        storeService.setQuestionnaireChangeHandler(this.updatePreview);
    }

    updatePreview = async () => {
        const data: Output = this.storeService.getStore().getState().general.questionnaires;

        if (
            [
                ...data.achievements,
                ...data.career,
                ...data.education,
                ...data.hobby,
                ...data.remarkableEvents,
                ...data.insights,
            ].length === 0
        ) {
            dispatcherService.setSvg('');
            return;
        }

        const url = process.env.REACT_APP_VIZ_BACKEND!;
        const request = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (request.status === 200) {
            dispatcherService.setSvg(await request.text());
        } else {
            await graylogService.sendMessage({
                short_message: '[' + request.status + ']  Request error: ' + request.url,
                full_message: '[' + request.status + ']  Request error: ' + request.url,
            });
        }
    };
}

const vizIntegrationService = (storeService: StoreService) =>
    new VizIntegrationService(storeService);

export default vizIntegrationService;
