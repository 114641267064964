import actions from '../actions/types';
import GeneralState from '../../types/GeneralState';
import StoreAction from '../../types/StoreAction';
import { updateObject } from './utility';

export const initialState: GeneralState = {
    condition: 'viewData',
    editingQuestionnaireIndex: 0,
    editingQuestionnaireType: '',
    questionnaires: {
        education: [],
        career: [],
        hobby: [],
        achievements: [],
        remarkableEvents: [],
        insights: [],
    },
    summaryQuestionnaires: {
        education: [],
        career: [],
        hobby: [],
    },
    editingSummaryQuestionnaireIndex: 0,
    editingSummaryQuestionnaireType: 'career',
    isLoadingQuestionnaires: false,
    loadErrorQuestionnaires: false,
    isSavingQuestionnaires: false,
    mode: 'select',
    warningType: 'none',
    integrationParams: {
        token: '',
        unti_id: '',
        activity_uuid: '',
    },
    fetchConfig: false,
    fetchConfigError: null,
    showMinQuestionnairesModal: false,
    firstLoad: false,
    retryCount: 0,
    retryDeadline: 15,
    retrying: false,
};

const general = (state: GeneralState = initialState, action: StoreAction) => {
    switch (action.type) {
        case actions.general.SET_QUESTIONNAIRE_EDUCATION: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.education.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.SET_QUESTIONNAIRE_CAREER: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.career.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.SET_QUESTIONNAIRE_HOBBY: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.hobby.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.SET_QUESTIONNAIRE_REMARKABLE_EVENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.remarkableEvents.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.SET_QUESTIONNAIRE_ACHIEVEMENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.achievements.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.SET_QUESTIONNAIRE_INSIGHT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.insights.push(action.payload.questionnaire);

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_EDUCATION: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.education = currentState.questionnaires.education.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_CAREER: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.career = currentState.questionnaires.career.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_HOBBY: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.hobby = currentState.questionnaires.hobby.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_REMARKABLE_EVENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.remarkableEvents = currentState.questionnaires.remarkableEvents.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_ACHIEVEMENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.achievements = currentState.questionnaires.achievements.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.REMOVE_QUESTIONNAIRE_INSIGHT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.insights = currentState.questionnaires.insights.filter(
                (item, index) => {
                    return index !== action.payload.index;
                }
            );

            return updateObject(state, currentState);
        }
        case actions.general.SET_CONDITION: {
            return { ...JSON.parse(JSON.stringify(state)), condition: action.payload.condition };
        }
        case actions.general.SET_EDITING_QUESTIONNAIRE_CONDITION: {
            return updateObject(state, {
                editingQuestionnaireIndex: action.payload.index,
                editingQuestionnaireType: action.payload.type,
            });
        }
        case actions.general.SET_ACHIEVEMENT_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.achievements = currentState.questionnaires.achievements.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_CAREER_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.career = currentState.questionnaires.career.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_EDUCATION_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.education = currentState.questionnaires.education.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_HOBBY_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.hobby = currentState.questionnaires.hobby.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_REMARKABLE_EVENT_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.remarkableEvents = currentState.questionnaires.remarkableEvents.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_INSIGHT_TITLE: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.insights = currentState.questionnaires.insights.map(
                (item, index) => {
                    if (index === action.payload.index) {
                        return updateObject(item, { title: action.payload.newTitle });
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_EDUCATION: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.education = currentState.questionnaires.education.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_CAREER: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.career = currentState.questionnaires.career.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_HOBBY: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.hobby = currentState.questionnaires.hobby.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_REMARKABLE_EVENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.remarkableEvents = currentState.questionnaires.remarkableEvents.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_ACHIEVEMENT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.achievements = currentState.questionnaires.achievements.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.CHANGE_QUESTIONNAIRE_INSIGHT: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires.insights = currentState.questionnaires.insights.map(
                (item, index) => {
                    if (index === state.editingQuestionnaireIndex) {
                        return updateObject(item, action.payload.questionnaire);
                    } else {
                        return item;
                    }
                }
            );

            return currentState;
        }
        case actions.general.SET_INTEGRATION_PARAMS: {
            let currentState = Object.assign({}, state);
            currentState.integrationParams = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_LOADED_QUESTIONNAIRES: {
            let currentState = Object.assign({}, state);
            currentState.questionnaires = {
                ...currentState.questionnaires,
                ...action.payload.questionnaires,
            };

            return updateObject(state, currentState);
        }
        case actions.general.GET_QUESTIONNAIRES_REQUEST: {
            let currentState = Object.assign({}, state);
            currentState.isLoadingQuestionnaires = true;

            return updateObject(state, currentState);
        }
        case actions.general.GET_QUESTIONNAIRES_SUCCESS: {
            let currentState = Object.assign({}, state);
            currentState.isLoadingQuestionnaires = false;

            return updateObject(state, currentState);
        }
        case actions.general.GET_QUESTIONNAIRES_FAILURE: {
            let currentState = Object.assign({}, state);
            currentState.isLoadingQuestionnaires = false;
            currentState.loadErrorQuestionnaires = true;

            return updateObject(state, currentState);
        }
        case actions.general.SET_SUMMARY_QUESTIONNAIRES: {
            let currentState = Object.assign({}, state);
            currentState.summaryQuestionnaires = action.payload.summaryQuestionnaires;

            return updateObject(state, currentState);
        }
        case actions.general.SET_EDITING_SUMMARY_QUESTIONNAIRE_CONDITION: {
            return updateObject(state, {
                editingSummaryQuestionnaireIndex: action.payload.index,
                editingSummaryQuestionnaireType: action.payload.type,
            });
        }
        case actions.general.SET_IS_SAVING_QUESTIONNAIRES: {
            return updateObject(state, {
                isSavingQuestionnaires: action.payload,
            });
        }
        case actions.general.SET_WARNING_TYPE: {
            let currentState = Object.assign({}, state);
            currentState.warningType = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_MODE: {
            let currentState = Object.assign({}, state);
            currentState.mode = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfig = true;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_SUCCESS: {
            let currentState = Object.assign({}, state);

            currentState = {
                ...currentState,
                ...action.payload,
            };
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.FETCH_CONFIG_ERROR: {
            let currentState = Object.assign({}, state);
            currentState.fetchConfigError = action.payload;
            currentState.fetchConfig = false;

            return updateObject(state, currentState);
        }
        case actions.general.SET_SHOW_MIN_QUESTIONNAIRES_MODAL: {
            let currentState = Object.assign({}, state);
            currentState.showMinQuestionnairesModal = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_FIRST_LOAD: {
            let currentState = Object.assign({}, state);
            currentState.firstLoad = action.payload;

            return updateObject(state, currentState);
        }
        case actions.general.SET_RETRY_COUNT: {
            return updateObject(state, { retryCount: action.payload });
        }
        case actions.general.SET_RETRY_DEADLINE: {
            return updateObject(state, { retryDeadline: action.payload });
        }
        case actions.general.SET_RETRYING: {
            return updateObject(state, { retrying: action.payload });
        }
        default:
            return state;
    }
};

export default general;
